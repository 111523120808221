// This is a modified raygun loader based on the example in the Raygun docs
// It reports errors that occur before the lazy loading completes
// Loading Raygun happens with a dynamic import call inside initializers/raygun.ts
// We can't load from the Raygun CDN because we sub-resource integrity hashes
// haven't yet been validated and approved by the security team, and we don't have
// protection if the CDN is compromised (Raygun runs on secure routes as well)

!function(a, b, c, d, e, f, g, h) {
  a.RaygunObject = e,
  a[e] = a[e] || function() {
      (a[e].o = a[e].o || []).push(arguments)
  }
  ,
  h = a.onerror,
  a.onerror = function(b, c, d, f, g) {
      h && h(b, c, d, f, g),
      g || (g = new Error(b)),
      a[e].q = a[e].q || [],
      a[e].q.push({
          e: g
      })
  }
}(window, document, "script", void 0, "rg4js");
